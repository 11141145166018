<section class="hero">
  <div class="hero__fake-background"></div>
  <h1 class="hero__title">Creator-led Products</h1>
</section>
<section class="for-creators">
  <div class="container">
    <h2 class="for-creators__title">Product Design and Manufacturing Service Built For Creators</h2>
    <div class="for-creators__info">
      <div class="for-creators__info__section">
        <p><b>We Are <br/>The Factory</b></p>
        <p>We either own or work inside the factories to ensure quality. </p>
      </div>
      <div class="for-creators__info__section">
        <p><b>Next-gen <br/>Materials</b></p>
        <p>Specialize in recycled, biobased, and next-generation materials. </p>
      </div>
      <div class="for-creators__info__section">
        <p><b>Full <br/>Service</b></p>
        <p>Design, material testing, sampling, prototyping, and production</p>
      </div>
      <div class="for-creators__info__section">
        <p><b>Transparent <br/>Supply Chains</b></p>
        <p>Product journey is monitored from raw materials to final product.</p>
      </div>
    </div>
  </div>
</section>
<section class="main container">
  <div class="main__products container">
    <div class="main__products__text">
      <h3 class="main__products__text__title">Our Products</h3>
      <p class="main__products__text__text">We have the ability to design and produce products ranging from more affordable to luxury and from pre-set to highly customized designs. Our current capabilities include:</p>
      <ul class="main__products__text__list with-bullets">
        <li>Apparel and accessories</li>
        <li>Automotive</li>
        <li>Bags</li>
        <li>Footwear</li>
        <li>Homegoods</li>
        <li>Hospitality</li>
        <li>Sporting goods</li>
        <li>Outerwear (coming soon...)</li>
      </ul>
    </div>
    <div class="main__products__image">
      <ul class="main__products__image__mobile-list with-bullets">
        <li>Apparel and accessories</li>
        <li>Hospitality</li>
        <li>Sporting goods</li>
        <li>Footwear</li>
        <li>Automotive</li>
        <li>Outerwear (coming soon...)</li>
      </ul>
      <img class="main__products__image__image" src="assets/images/creators/photo_products.webp" alt="produced products">
    </div>
  </div>
  <div class="main__materials container">
    <div class="main__materials__text">
      <h3 class="main__materials__text__title">Our Materials</h3>
      <p class="main__materials__text__text">We work with 20+ suppliers at the forefront of sustainability and utilize these materials wherever possible.  Our number #1 rule - NO GREENWASHING.</p>
      <ul class="main__materials__text__list">
        <li>Biobased</li>
        <li>Recycled</li>
        <li>Next-generation materials</li>
      </ul>
    </div>
    <div class="main__materials__image">
      <ul class="main__materials__image__mobile-list">
        <li>Bioplastics</li>
        <li>Recycled nylon</li>
        <li>Next-generation</li>
      </ul>
      <img class="main__materials__image__image" src="assets/images/creators/photo_materials.webp" alt="used materials">
    </div>
  </div>
  <div class="main__values container">
    <div class="main__values__text">
      <h3 class="main__values__text__title">Our Values</h3>
      <p class="main__values__text__text" *ngIf="false">text</p>
      <ul class="main__values__text__list">
        <li><b>Quality</b> comes first</li>
        <li><b>Obsessive</b> over product development</li>
        <li><b>Radical transparency</b> with every part of our process</li>
        <li><b>Respect</b> people along the entire value chain</li>
      </ul>
    </div>
    <div class="main__values__image">
      <div class="main__values__image__text main__values__image__text__top">Welcome to</div>
      <img src="assets/images/creators/photo_map_custom_white.webp" alt="world map"/>
      <div class="main__values__image__text main__values__image__text__bottom">conscious manufacturing</div>
    </div>
  </div>
  <div class="main__factories container">
    <div class="main__factories__text">
      <h3 class="main__factories__text__title">Our Factories</h3>
      <p class="main__factories__text__text">We have partnered with <a href="https://www.veshinfactory.com/" target="_blank">Veshin Factory</a> to provide creators the opportunity to design and produce products direct from the factory.</p>
    </div>
    <div class="main__factories__images">
      <div class="main__values__countries">
        <img src="assets/images/creators/photo_columbia.webp" alt="our factory in columbia"/>
        <img src="assets/images/creators/photo_italy.webp" alt="our factory in italy"/>
        <img src="assets/images/creators/photo_china.webp" alt="our factory in china"/>
        <img src="assets/images/creators/photo_brazil.webp" alt="our factory in brazil"/>
      </div>
    </div>
  </div>
  <div class="main__collaboration container">
    <h2 class="main__collaboration__title">Work with Us</h2>
    <div class="main__collaboration__items">
      <div class="main__collaboration__items__item">
        <p class="main__collaboration__items__item__text">
          The first step is to fill out the form below to tell us more about what you want to make. We will get back to you ASAP to set up an initial call.
        </p>
        <a class="main__collaboration__items__item__button button is-success wide is-medium has-text-weight-bold is-halfwidth"
           href="https://share-eu1.hsforms.com/2JhNSVtEpTf-y1K3Ki-HZ0wf1g9n"
           target="_blank">
          Get started
        </a>
      </div>
      <div class="main__collaboration__items__item">
        <p class="main__collaboration__items__item__text">
          Learn more about how we work and our process.
        </p>
        <button class="main__collaboration__items__item__button button is-success wide is-medium has-text-weight-bold is-halfwidth"
                routerLink="./faq">
          FAQs
        </button>
      </div>
    </div>
    <div class="main__collaboration__action">
      <h3>Bring your ideas, we do the rest</h3>
    </div>
  </div>
</section>
