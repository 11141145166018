import {Injectable} from "@angular/core";
import {finalize, from, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {ElasticSearchService} from "./elasticsearch.service";
import {CompanySearchResponse, ProductSearchResponse} from "../../api/cs";

import {FiltersData} from "../models/filter-data.model";
import {SearchRequestEntity} from "../models/search-request-entity.model";
import {SupplyChainSearchResult} from "../models/supply-chain-search-result.model";
import {FilterNode} from "../models/filter-node.model";
import {HeadingFilter} from "../models/heading-filter.model";
import {SearchResponse} from "../models/search-response.model";

/**
 * Interface that describes how communicate with supply chain info
 * Should be abstract class to allow correct dependency injection in module
 */
export abstract class SupplyChainBuilderService {
  public abstract getFiltersData(): Observable<FiltersData>;
  public abstract performSearch(searchRequest?: SearchRequestEntity): Observable<SupplyChainSearchResult>;
}

class SelectedItems {
  selectedValues: string[];
  selectedGroups: string[];

  constructor(selectedValues: string[], selectedGroups: string[]) {
    this.selectedValues = selectedValues;
    this.selectedGroups = selectedGroups;
  }
}

@Injectable()
export class SupplyChainBuilderServiceImpl implements SupplyChainBuilderService {

  private searchResponse: SearchResponse;

  private filtersData: FiltersData;

  private isFilterLoaded: Promise<any>;

  constructor(private httpClient: HttpClient,
              private elasticSearchService: ElasticSearchService) {
    this.isFilterLoaded = this.prepareFilters();
  }

  getFiltersData(): Observable<FiltersData> {
    return from(new Promise<FiltersData>( async resolve => {
      await this.isFilterLoaded.then(() => resolve(this.filtersData))
    }));
  }

  performSearch(searchRequest?: SearchRequestEntity): Observable<SupplyChainSearchResult> {
    return from(new Promise<SupplyChainSearchResult>( async resolve => {
      this.elasticSearchService.searchByTextRequest(searchRequest)
        .subscribe(async searchResponse => {
          this.searchResponse = searchResponse;
          resolve(await this.doFilter(this.searchResponse, searchRequest));
      });
    }));
  }

  private async prepareFilters(): Promise<any> {
    if (!this.filtersData) {
      await this.loadFiltersData().then(data => this.filtersData = data);
    }
  }

  private async loadFiltersData(): Promise<FiltersData> {
    return new Promise(resolve => {
      let filterData: any;
      this.httpClient.get("https://" + environment.s3BucketName + '.s3.eu-west-3.amazonaws.com/filters/filters.json', { responseType: 'text' })
        .pipe(finalize(() => resolve(filterData)))
        .subscribe(data => {
          filterData = <FilterNode[]>(JSON.parse(data));
        });
    });
  }

  /**
   * Describes filter chain if searchRequest exists
   * 1. Filtering by heading filters
   * 2. Filtering by side filters
   * 3. Generating separate list of products
   * 3. Filtering by text search both of lists (products, companies)
   * If searchRequest doesn't exist returns source list
   * @private
   */
  public async doFilter(supplyChainData: SearchResponse, searchRequest?: SearchRequestEntity): Promise<SupplyChainSearchResult> {
    let companyList: CompanySearchResponse[];
    let productList: ProductSearchResponse[];
    if (!!searchRequest) {
      //NOTE Creates clone for each object to avoid overriding origin data
      companyList = supplyChainData?.companies?.map(company => Object.assign({}, company));
      companyList = this.doFilterByProperties(companyList, searchRequest.headingFilters);
      companyList = this.doFilterByValueChain(companyList, searchRequest.filterType, searchRequest.filterNodes);

      productList = companyList.flatMap(company => !!company.products ? company.products : []);
    } else {
      companyList = supplyChainData.companies;
      productList = supplyChainData?.companies?.flatMap(company => !!company.products ? company.products : []);
    }

    return new Promise(resolve => resolve(
      new SupplyChainSearchResult(
        companyList,
        productList,
        supplyChainData.filtersToSell,
        supplyChainData.filtersToUse
      ))
    );
  }

  /**
   * Sorts data by header filters
   * There are three types of sorting
   * 1. Filter by product property: Finds all products that have such property and filter only companies that have product with such property
   * 2. Filter by company property: Finds companies that have this property and all their products
   * 3. Filter by common property: Finds companies that have such property and theirs products that also have it
   * @param sourceList - list of previously filtered companies or list of all companies
   * @param headingFilters
   */
  public doFilterByProperties(sourceList: CompanySearchResponse[], headingFilters: HeadingFilter[]): CompanySearchResponse[] {

    for (let headingFilter of headingFilters) {
      switch (headingFilter.applicability) {
        case "COMPANY": sourceList = this.doFilterByCompanyProperty(headingFilter, sourceList); break;
        case "PRODUCT": sourceList = this.doFilterByProductProperty(headingFilter, sourceList); break
        case "BOTH": sourceList = this.doFilterByCommonProperty(headingFilter, sourceList); break;
      }
    }

    return sourceList;
  }

  /**
   * Filters products that have chosen filter value and filter companies which this products produce
   * @param filter
   * @param sourceList
   * @private
   */
  private doFilterByProductProperty(filter: HeadingFilter, sourceList: CompanySearchResponse[]): CompanySearchResponse[] {
    if (!filter?.chosenValues || filter?.chosenValues?.length == 0) {
      return sourceList;
    }
    let selectedItems = this.collectSelectedItems(filter);
    return sourceList.filter(company => {
      company.products = company.products?.filter((product: ProductSearchResponse) => {
        let isFound: boolean = false;
        //@ts-ignore
        let groupFieldValues: string[] = (<string[]>(product[filter.matchingGroupField]?.toString()));
        //@ts-ignore
        let value = product[filter.matchingValueField];
        let valueFieldValues: string[];
        if (value instanceof Array) {
          valueFieldValues = (<string[]>(value));
        } else {
          valueFieldValues = [value];
        }
        groupFieldValues?.forEach(value => selectedItems.selectedGroups.includes(value) ? isFound = true : {});
        valueFieldValues?.forEach(value => selectedItems.selectedValues.includes(value) ? isFound = true : {});
        return isFound;
      });
      return (company.products?.length || 0) > 0;
    })
  }

  /**
   * Filters companies products by product filter
   * Left companies that doesn't have products in list
   * @param filter
   * @param sourceList
   * @private
   */
  private doFilterByCommonProperty(filter: HeadingFilter, sourceList: CompanySearchResponse[]): CompanySearchResponse[] {
    if (!filter?.chosenValues || filter?.chosenValues?.length == 0) {
      return sourceList;
    }
    let selectedItems = this.collectSelectedItems(filter);
    return sourceList.filter(company => {
      company.products = company.products?.filter((product: ProductSearchResponse) => {
        let isFound: boolean = false;
        //@ts-ignore
        let productGroupMatchingFieldValue = product["product" + filter.matchingGroupField];
        let groupFieldValues: string[] = typeof productGroupMatchingFieldValue == 'string' ? Array.of(productGroupMatchingFieldValue) : productGroupMatchingFieldValue;
        //@ts-ignore
        let productValueMatchingFieldValue = product["product" + filter.matchingValueField];
        let valueFieldValues: string[] = typeof productValueMatchingFieldValue == 'string' ? Array.of(productValueMatchingFieldValue) : productValueMatchingFieldValue;
        groupFieldValues?.forEach(value => selectedItems.selectedGroups.includes(value) ? isFound = true : {});
        valueFieldValues?.forEach(value => selectedItems.selectedValues.includes(value) ? isFound = true : {});
        return isFound;
      });
      let isFound: boolean = false;
      //@ts-ignore
      let companyGroupMatchingFieldValue = company["company" + filter.matchingGroupField];
      let groupFieldValues: string[] = typeof companyGroupMatchingFieldValue == 'string' ? Array.of(companyGroupMatchingFieldValue) : companyGroupMatchingFieldValue;
      //@ts-ignore
      let companyValueMatchingFieldValue = company["company" + filter.matchingValueField];
        let valueFieldValues: string[] = typeof companyValueMatchingFieldValue == 'string' ? Array.of(companyValueMatchingFieldValue) : companyValueMatchingFieldValue;
      groupFieldValues?.forEach(value => selectedItems.selectedGroups.includes(value) ? isFound = true : {});
      valueFieldValues?.forEach(value => selectedItems.selectedValues.includes(value) ? isFound = true : {});
      return isFound;
    })
  }

  /**
   * Filters companies by filter value.
   * Left all products that it had before
   * @param filter
   * @param sourceList
   * @private
   */
  private doFilterByCompanyProperty(filter: HeadingFilter, sourceList: CompanySearchResponse[]): CompanySearchResponse[] {
    if (!filter?.chosenValues || filter?.chosenValues?.length == 0) {
      return sourceList;
    }
    let selectedItems = this.collectSelectedItems(filter);
    return sourceList.filter(company => {
      let isFound: boolean = false;
      //@ts-ignore
      let groupFieldValues: string[] = (<string>company[filter.matchingGroupField]?.toString())
        ?.replace("[", "")
        .replace("]", "")
        .split(', ') || [];
      //@ts-ignore
      let valueFieldValues: string[] = (<string>company[filter.matchingValueField].toString())
        ?.replace("[", "")
        .replace("]", "")
        .split(', ') || [];
      groupFieldValues?.forEach(value => selectedItems.selectedGroups.includes(value) ? isFound = true : {});
      valueFieldValues?.forEach(value => selectedItems.selectedValues.includes(value) ? isFound = true : {});
      return isFound;
    });
  }

  private collectSelectedItems(filter: HeadingFilter): SelectedItems {
    let selectedGroups: string[] = [];
    filter.chosenValues.map(value => {
      if (!!value.matchingGroupName && !value.matchingValue) {
        selectedGroups.push(value.matchingGroupName);
      }
    });
    let selectedValues: string[] = [];
    filter.chosenValues.forEach(value => {
      if (!selectedGroups.includes(value.matchingGroupName || '')) {
        selectedValues.push(value.matchingValue);
        if (!!value.additionalMatchingValues) {
          selectedValues.push(...value.additionalMatchingValues);
        }
      }
    });
    return new SelectedItems(selectedValues, selectedGroups);
  }

  /**
   * Filters data by side filters value
   * Returns source list if both of options ("SELL" | "USE") are unselected
   * If any of them is selected then make filtering
   * If both are selected then make filtering and removes duplicates
   * @param sourceList
   * @param filterType
   * @param filterNodes
   * @private
   */
  public doFilterByValueChain(sourceList: CompanySearchResponse[], filterType: "USE" | "SELL", filterNodes: FilterNode[]): CompanySearchResponse[] {
    let resultList: CompanySearchResponse[] = [];
    if (filterNodes.find(node => this.collectAllChildrenNodes(node).filter(child => child.status).length > 0)) {
      if (filterType == "SELL") {
        resultList.push(...this.doFilterByFinalChainValue(sourceList, filterNodes));
      }
      if (filterType == "USE") {
        resultList.push(...this.doFilterByIntermediateChainValue(sourceList, filterNodes));
      }
      return resultList;
    } else {
      return sourceList;
    }
  }

  /**
   * Method that sorts values by side filter in case if "USE" option is selected
   * @param sourceList
   * @param filterNodes
   * @private
   */
  private doFilterByIntermediateChainValue(sourceList: CompanySearchResponse[], filterNodes: FilterNode[]): CompanySearchResponse[] {
    let filteredResult: CompanySearchResponse[] = sourceList;
    filterNodes.forEach(filterNode => {
      let filteredItems: CompanySearchResponse[] = [];
      let childrenNodes = this.collectAllChildrenNodes(filterNode);
      childrenNodes.forEach(filter => {
        if (filter.status) {
          filteredResult.forEach(company => {
            let sortedProducts = company.products?.filter((product: ProductSearchResponse) =>
              product.productSupplyChainFlow?.includes(filter.value) && !product.finalSupplyChainFlowStage?.includes(filter.value));
            if ((sortedProducts?.length || 0) > 0) {
              if (filteredItems.includes(company)) {
                sortedProducts?.forEach((product: ProductSearchResponse) => {
                  if (!company.products?.includes(product)) {
                    company.products?.push(product);
                  }
                })
              } else {
                company.products = sortedProducts;
                filteredItems.push(company);
              }
            }
          });
        }
      })
      if (filteredItems.length > 0 || childrenNodes.find(node => node.status)) {
        filteredResult = filteredItems;
      }
    });
    return filteredResult;
  }

  private collectAllChildrenNodes(filterNode: FilterNode): FilterNode[] {
    let childrenNodes: FilterNode[] = [];
    childrenNodes.push(filterNode);
    filterNode.children?.forEach(child => childrenNodes.push(...this.collectAllChildrenNodes(child)));
    return childrenNodes;
  }

  /**
   * Method that sorts values by side filter in case if "SELL" option is selected
   * @param sourceList
   * @param filterNodes
   * @private
   */
  private doFilterByFinalChainValue(sourceList: CompanySearchResponse[], filterNodes: FilterNode[]): CompanySearchResponse[] {
    let filteredResult: CompanySearchResponse[] = [];
    filterNodes.forEach(filterNode => {
      if (this.isSubtreeSelected(filterNode)) {
        let selectedChildren = this.getSelectedChildrenNodes(filterNode);
        sourceList.forEach(company => {
          let sortedProducts = company.products?.filter((product: ProductSearchResponse) => {
            let isMatched: boolean = false;
            selectedChildren.forEach(child => {
              if (product.finalSupplyChainFlowStage?.includes(child.value)) {
                isMatched = true;
              }
            })
            return isMatched;
          });
          if ((sortedProducts?.length || 0) > 0) {
            if (filteredResult.includes(company)) {
              let existCompany = filteredResult.find(() => company);
              sortedProducts?.forEach((product: ProductSearchResponse) => {
                if (!existCompany?.products?.includes(product)) {
                  existCompany?.products?.push(product);
                }
              })
            } else {
              company.products = sortedProducts;
              filteredResult.push(company);
            }
          }
        })
      }
    });
    return filteredResult;
  }

  private isSubtreeSelected(filterNode: FilterNode): boolean {
    if (filterNode.status) {
      return true;
    } else {
      if (!!filterNode.children) {
        return filterNode.children.filter(child => this.isSubtreeSelected(child)).length > 0;
      } else {
        return false;
      }
    }
  }

  private getSelectedChildrenNodes(filterNode: FilterNode): FilterNode[] {
    return this.collectAllChildrenNodes(filterNode).filter(node => node.status);
  }
}
