import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform
} from '@angular/core';
import {FormControl} from "@angular/forms";
import {finalize, Observable, take} from "rxjs";
import {LoaderService} from "../../../shared/services/loader.service";
import {PlatformImageService} from "../../../shared/services/platform-image.service";
import {NestedTreeControl} from "@angular/cdk/tree";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {MatDialog} from "@angular/material/dialog";
import {HubspotFormModalComponent} from "../../../shared/components/hubspot-form-modal/hubspot-form-modal.component";
import {HubSpotLiveChatService} from "../../../shared/services/hubspot-live-chat.service";
import {CompanySearchResponse, ProductSearchResponse} from "../../../api/cs";
import {Title} from "@angular/platform-browser";
import { SupplyChainSearchResult } from '../../models/supply-chain-search-result.model';
import {FiltersData} from "../../models/filter-data.model";
import {FilterNode} from "../../models/filter-node.model";
import {SupplyChainBuilderService} from "../../services/supply-chain-builder.service";
import {HeadingFilter} from "../../models/heading-filter.model";
import {SearchRequestEntity} from "../../models/search-request-entity.model";

@Pipe({
  name: "deleteCategoryNumberIdentifier",
  pure: true
})
export class DeleteCategoryNumberIdentifierPipe implements PipeTransform {

  sequenceOfNumbersAndDotsRegexp: RegExp = /[\d.]+/;

  transform(category: string | undefined): string | undefined {
    category = category?.replace(" ,", ",");
    while (category?.match(this.sequenceOfNumbersAndDotsRegexp)) {
      category = category?.replace(this.sequenceOfNumbersAndDotsRegexp, '');
    }
    return category;
  }
}

@Pipe({
  name: "cutTextIfItGreaterThenLimit",
  pure: true
})
export class CutTextIfItGreaterThenLimitPipe implements PipeTransform {

  transform(inputString: string | undefined, divider: number): any {
    let maxDescriptionLength: number = window.innerWidth / (window.innerWidth >= 1480 ? divider : divider / 2);
    if ((inputString?.length || 0) > maxDescriptionLength) {
      return inputString?.substring(0, maxDescriptionLength).concat("...");
    } else {
      return inputString;
    }
  }
}

@Pipe({
  name: "transformArrayToStringPipe",
  pure: true
})
export class TransformArrayToStringPipe implements PipeTransform {

  transform(inputArray: string[] | undefined): any {
    return inputArray?.join(", ");
  }
}

class SelectedGeneralFilterRecord {
  groupName: string;
  filterName: string;
  constructor(groupName: string, filterName: string) {
    this.groupName = groupName;
    this.filterName = filterName;
  }
}

@Component({
  selector: 'cs-supply-chain-builder',
  templateUrl: './supply-chain-builder.component.html',
  styleUrls: ['./supply-chain-builder.component.scss']
})
export class SupplyChainBuilderComponent implements OnInit, AfterViewChecked, OnDestroy {

  searchResult: SupplyChainSearchResult;
  filtersData: FiltersData;

  isSideFiltersVisible: boolean = false;
  treeControl = new NestedTreeControl<FilterNode>(node => node.children);
  treeDataSource = new MatTreeNestedDataSource<FilterNode>();
  hasChild = (_: number, node: FilterNode) => !!node.children && node.children.length > 0;
  isFirstNode = (node: FilterNode) => this.filtersData.filterNodes.filter(parentNode => parentNode?.name == node?.name).length > 0;
  currentGroupIdentifier: FilterNode;
  sellFilterControl: FormControl = new FormControl(true);
  useFilterControl: FormControl = new FormControl(false);
  nodeControlMap: Map<String, FormControl> = new Map<String, FormControl>();
  filterGroups: Map<FilterNode, FilterNode[]> = new Map<FilterNode, FilterNode[]>();
  availabilityNodeMap: Map<FilterNode, boolean> = new Map<FilterNode, boolean>();

  filterGroupsActiveCount: Map<String, number> = new Map<String, number>();

  search = new FormControl('');
  filteredOptions: Observable<string[]>;

  companiesSortedList: CompanySearchResponse[];
  productsSortedList: ProductSearchResponse[];

  filtersToSell: string[];
  filtersToUse: string[];

  chosenFilters: SelectedGeneralFilterRecord[] = []; //contains names of selected filters to show them as tags below

  windowScrolled: boolean = false;

  companyPageIndex: number = 0;
  productPageIndex: number = 0;
  itemsPerPage: number = 20;

  chosenAlgaeType: "All" | "Seaweed/Macro-algae" | "Micro-algae" = "All";
  algaeTypes = ["All", "Seaweed/Macro-algae", "Micro-algae"];

  chosenTargetType: "For SALE" | "USED to make other products" = "For SALE";
  targetTypes = ["For SALE", "USED to make other products"];

  chosenTab: "COMPANY" | "PRODUCT" = "COMPANY";

  scrollToTopButtonAppearanceOffset: number = 500;

  hubSpotMeetingSchedulingLink: string = "https://meetings-eu1.hubspot.com/jwolfe/algae-verification-call";

  private static readonly FEEDBACK_HUBSPOT_EMBED_CODE = {
    title: "Feedback Form",
    form: {
      portalId: "25261979",
      formId: "6af9f21e-b09e-4253-8e5d-0d8a63e98956",
      target: "#hubspotForm"
    }
  };

  constructor(private loader: LoaderService,
              private dialog: MatDialog,
              private titleService: Title,
              private hubSpotLiveChatService: HubSpotLiveChatService,
              private supplyChainSearchService: SupplyChainBuilderService,
              public platformImageService: PlatformImageService) { }

  ngOnInit(): void {
    this.titleService.setTitle("Supply Chain Builder | Cultured Supply");
    this.loader.show();
    this.hubSpotLiveChatService.hideLiveChat();
    this.supplyChainSearchService.getFiltersData()
      .pipe(take(1))
      .subscribe(filtersData => this.processSupplyChainFiltersData(filtersData));
    this.supplyChainSearchService.performSearch()
      .pipe(take(1))
      .subscribe(searchResult => this.processSupplyChainData(searchResult));
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset > this.scrollToTopButtonAppearanceOffset;
    });
  }

  ngAfterViewChecked() {
    this.removeNgHasValueClass();
  }

  handleTabChange(event: number): void {
    if (event == 0) {
      this.chosenTab = "COMPANY";
    } else if (event == 1) {
      this.chosenTab = "PRODUCT";
    }
  }

  getFilters(): HeadingFilter[] {
    return this.filtersData?.headingFilters.slice(2).filter(filter => filter.applicability == this.chosenTab);
  }

  removeNgHasValueClass() {
    //NOTE ng-select library adds "ng-has-value" class to every dropdown where values are selected.
    //After we override ng-multi-label-tmp component has better behavior without it
    let elementsByClassName = document.getElementsByClassName('ng-select-container');
    for (let i = 0; i < elementsByClassName.length; i++) {
      elementsByClassName.item(i)?.classList.remove("ng-has-value");
    }
  }

  getCurrentPage(list: CompanySearchResponse[], pageIndex: number): CompanySearchResponse[];
  getCurrentPage(list: ProductSearchResponse[], pageIndex: number): ProductSearchResponse[];
  getCurrentPage(list: any[], pageIndex: number): any[] {
    let startIndex = !!list ? pageIndex * this.itemsPerPage : 0;
    let endIndex = !!list ? (pageIndex + 1) * this.itemsPerPage < list?.length
      ? (pageIndex + 1) * this.itemsPerPage
      : list?.length : this.itemsPerPage;
    return list?.slice(startIndex, endIndex);
  }

  ngOnDestroy() {
    this.hubSpotLiveChatService.showLiveChat();
  }

  scrollToTop(): void {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }

  handleCompanyPageEvent(event: any) {
    this.companyPageIndex = event.pageIndex;
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }
  handleProductPageEvent(event: any) {
    this.productPageIndex = event.pageIndex;
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }

  private processSupplyChainData(searchResult: SupplyChainSearchResult): void {
    this.searchResult = searchResult;
    this.companiesSortedList = searchResult.companies;
    this.productsSortedList = searchResult.products;
    this.filtersToSell = searchResult.filtersToSell;
    this.filtersToUse = searchResult.filtersToUse;
    this.resolveNodesAvailability(this.sellFilterControl.value ? "SELL" : "USE",
      this.getDisabledAlgaeType(this.chosenAlgaeType));
  }

  private processSupplyChainFiltersData(filtersData: FiltersData): void {
    this.filtersData = filtersData;
    this.treeControl.dataNodes = [];
    this.addTreeControls(filtersData.filterNodes);
    this.setUpFilters();
    this.treeDataSource.data = this.filtersData.filterNodes;
    this.resolveNodesAvailability(this.useFilterControl.value ? "SELL" : "USE",
      this.getDisabledAlgaeType(this.chosenAlgaeType));
    this.isSideFiltersVisible = true;
    this.loader.hide();
  }

  public changeFilterType(): void {
    this.useFilterControl.setValue(!this.useFilterControl.value);
    this.sellFilterControl.setValue(!this.sellFilterControl.value);
    this.resolveNodesSelectStatus(this.sellFilterControl.value ? "SELL" : "USE",
      this.getDisabledAlgaeType(this.chosenAlgaeType));
    this.doFilter();
  }

  private getDisabledAlgaeType(selectedAlgaeType: "All" | "Micro-algae" | "Seaweed/Macro-algae"): "Micro-algae" | "Macro-algae" | undefined {
    if (selectedAlgaeType == "All") {
      return undefined;
    } else {
      return selectedAlgaeType == "Seaweed/Macro-algae" ? "Micro-algae" : "Macro-algae";
    }
  }

  private setUpFilters(): void {
    this.nodeControlMap?.forEach(formControl => {
      formControl.valueChanges.subscribe(() => {
        this.doFilter();
        this.setSelectedItemCount();
      });
    });
  }

  public processTreeNodeValueChange(node: FilterNode): void {
    let nodeValue = this.nodeControlMap.get(node.name)?.value;
    node.status = nodeValue;
    this.setStatusToNode(nodeValue, node, this.filtersData.filterNodes);
    if (nodeValue) {
      this.selectTreeNodeChildren(node);
    } else {
      this.unselectTreeNodeChildren(node);
      this.unselectTreeNodeParent(node);
    }
  }

  private selectTreeNodeChildren(parentNode: FilterNode): void {
    parentNode.children?.forEach(child => {
      if (this.availabilityNodeMap.get(child)) {
        this.setStatusToNode(true, child, this.filtersData.filterNodes);
        this.nodeControlMap.get(child.name)?.setValue(true);
        if (!!child.children) {
          this.selectTreeNodeChildren(child);
        }
      }
    });
  }

  private unselectTreeNodeChildren(parentNode: FilterNode): void {
    parentNode.children?.forEach(child => {
      this.setStatusToNode(false, child, this.filtersData.filterNodes);
      this.nodeControlMap.get(child.name)?.setValue(false);
      if (!!child.children) {
        this.unselectTreeNodeChildren(child);
      }
    });
  }

  private unselectTreeNodeParent(childNode: FilterNode): void {
    this.filterGroups.forEach((values) => {
      values.forEach(value => {
        if (value == childNode) {
          let parentNode = values.find(node => node.children?.includes(value))!;
          this.setStatusToNode(false, parentNode, this.filtersData.filterNodes);
          this.nodeControlMap.get(parentNode?.name)?.setValue(false);
          if (!this.isFirstNode(parentNode)) {
            this.unselectTreeNodeParent(parentNode);
          }
        }
      });
    });
  }

  /**
   * Sets predicted name to search bar after user choose it in dropdown
   * //NOTE Additional type checking allow to avoid situation when FormControl becomes set as search value
   * @param value
   */
  setSearchValue(value: string | object) {
    if (typeof value == "string") {
      this.search.setValue(value);
    }
  }

  /**
   * Method that will initiate filtering after user stop typing in search bar
   * or when he presses search button
   * @param immediately
   */
  doSearch(immediately: boolean): void {
    if (immediately) {
      this.doFilter();
    } else {
      let text = this.search.value;
      setTimeout(() => {
        if (this.search.value == text) {
          this.doFilter();
        }
      }, 1000);
    }
  }

  unselectHeadingFilterByRecord(unselectedFilter: SelectedGeneralFilterRecord): void {
    let filter = this.filtersData.headingFilters
      .find(filter => filter.name == unselectedFilter.groupName);
    filter!.chosenValues = filter!.chosenValues.filter(value => value.matchingValue != unselectedFilter.filterName);
    this.processHeadingFilterChange();
  }

  unselectHeadingFilterByFilterValue(headingFilter: HeadingFilter, unselectedFilterName: string): void {
      let filter = this.filtersData.headingFilters
          .find(filter => filter.name == headingFilter.name);
      filter!.chosenValues = filter!.chosenValues.filter(value => value.displayedName != unselectedFilterName);
      this.processHeadingFilterChange();
  }


  processHeadingFilterChange(): void {
    this.setTopFiltersValues();
    this.setFiltersTags();
    this.doFilter();
  }

  setTopFiltersValues(): void {
    // @ts-ignore
    this.filtersData.headingFilters.find(filter => filter.name == "Algae Type")?.chosenValues = [];
    this.resolveNodesSelectStatus(this.sellFilterControl.value ? "SELL" : "USE",
      this.getDisabledAlgaeType(this.chosenAlgaeType));
    if (this.chosenAlgaeType == "Seaweed/Macro-algae" || this.chosenAlgaeType == "Micro-algae") {
      this.filtersData.headingFilters.find(filter => filter.name == "Algae Type")?.chosenValues.push(
        this.filtersData.headingFilters.find(filter => filter.name == "Algae Type")?.values
          .find(value => value.displayedName == this.chosenAlgaeType)!
      )
    }
  }

  setFiltersTags(): void {
    this.chosenFilters = [];
    this.filtersData.headingFilters.slice(2).forEach(filter => {
      if (!!filter.chosenValues && filter.chosenValues?.length > 0) {
        filter.chosenValues.forEach(value => {
          this.chosenFilters.push(new SelectedGeneralFilterRecord(filter.name, value.matchingValue));
        })
      }
    })
  }

  doFilter(): void {
    this.supplyChainSearchService.performSearch(new SearchRequestEntity(
      this.useFilterControl.value ? "USE" : "SELL",
      this.search.value,
      this.filtersData.headingFilters,
      this.filtersData.filterNodes
    )).pipe(
      take(1),
      finalize(() => {
        this.companyPageIndex = 0;
        this.productPageIndex = 0;
    }))
      .subscribe(searchResult => this.processSupplyChainData(searchResult));
  }

  clearSideFilters(): void {
    this.filtersData.filterNodes.forEach(node => this.collectAllChildrenNodes(node).forEach(child => child.status = false));
    this.nodeControlMap.forEach((value) => {
      value.setValue(false);
    });
  }

  private collectAllChildrenNodes(filterNode: FilterNode): FilterNode[] {
    let childrenNodes: FilterNode[] = [];
    childrenNodes.push(filterNode);
    filterNode.children?.forEach(child => childrenNodes.push(...this.collectAllChildrenNodes(child)));
    return childrenNodes;
  }

  clearHeadingFilters(): void {
    this.filtersData.headingFilters.forEach(filter => filter.chosenValues = []);
    this.doFilter();
  }

  /**
   * Sets count of selected item after it changes
   */
  setSelectedItemCount(): void {
    this.filterGroups.forEach((values, key) => {
      if (this.filterGroupsActiveCount.get(key.name) == null) {
        this.filterGroupsActiveCount.set(key.name, 0);
      }
      this.filterGroupsActiveCount.set(key.name, values.filter(value => this.nodeControlMap.get(value.name)?.value).length);
    });
  }

  private addTreeControls(nodes: FilterNode[]): void {
    for (let node of nodes) {
      this.treeControl.dataNodes.push(node);
      this.availabilityNodeMap.set(node, false);
      let formControl = new FormControl(false);
      this.nodeControlMap.set(node.name, formControl);
      if (this.isFirstNode(node)) {
        this.currentGroupIdentifier = node;
        this.filterGroups.set(this.currentGroupIdentifier, []);
      }
      if (!!node.children) {
        this.filterGroups.get(this.currentGroupIdentifier)?.push(...node.children);
        this.addTreeControls(node.children);
      }
    }
  }

  private setStatusToNode(value: boolean, targetNode: FilterNode, searchScope: FilterNode[]): void {
    searchScope.forEach(node => {
      if (node?.name == targetNode?.name) {
        node.status = value;
      } else {
        if (!!node.children && node.children?.length > 1) {
          this.setStatusToNode(value, targetNode, node.children);
        }
      }
    });
  }

  public showFeedbackModal(): void {
    this.dialog.open(HubspotFormModalComponent, {
      panelClass: 'custom-dialog-container',
      data: SupplyChainBuilderComponent.FEEDBACK_HUBSPOT_EMBED_CODE
    });
  }

  public navigateToUrl(companyAlias?: string, productAlias?: string): void {
    let url: string = '/companies/' + companyAlias;
    if (!!productAlias) {
      url = url.concat('/products/').concat(productAlias);
    }
    window.open(url, "_blank");
  }

  private resolveNodesAvailability(availableFilterType: "SELL" | "USE",
                                   disabledAlgaeType: "Micro-algae" | "Macro-algae" | undefined): void {
    let availableFiltersList = availableFilterType == "SELL" ? this.filtersToSell : this.filtersToUse;
    this.availabilityNodeMap.forEach((value, key, map) => {
        map.set(key, availableFiltersList?.includes(key.value) || this.nodeControlMap.get(key.name)?.value);
    });
    this.availabilityNodeMap.forEach((value, key, map) => {
      if (disabledAlgaeType?.startsWith(key.name)) {
        this.setNodeAsDisabled(this.nodeControlMap, map, key);
      }
    });
  }

  private setNodeAsDisabled(formControlMap: Map<String, FormControl>,
                            availabilityMap: Map<FilterNode, boolean>,
                            node: FilterNode): void {
    availabilityMap.set(node, false);
    this.setStatusToNode(false, node, this.filtersData.filterNodes);
    if (formControlMap.get(node.name)?.value) {
      formControlMap.get(node.name)?.setValue(false);
    }
    if (!!node.children) {
      node.children.forEach(child => {
        this.setNodeAsDisabled(formControlMap, availabilityMap, child);
      });
    }
  }

  private resolveNodesSelectStatus(availableFilterType: "SELL" | "USE",
                                   disabledAlgaeType: "Micro-algae" | "Macro-algae" | undefined): void {
    let availableFiltersList = availableFilterType == "SELL" ? this.filtersToSell : this.filtersToUse;
    this.resolveNodesAvailability(availableFilterType, disabledAlgaeType);
    this.nodeControlMap.forEach((formControl, nodeName) => {
      if (formControl.value) {
        this.availabilityNodeMap.forEach((availabilityStatus, node) => {
          if (node.name == nodeName) {
            if (!availableFiltersList?.includes(node.value) || disabledAlgaeType?.startsWith(node.name)) {
              formControl.setValue(false);
              this.setStatusToNode(false, node, this.filtersData.filterNodes);
            } else {
              if (!!node.children) {
                this.selectTreeNodeChildren(node);
              }
            }
          }
        })
      }
    })
  }
}
