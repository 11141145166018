import {Component, OnInit} from '@angular/core';
import {marked} from "marked";

@Component({
  selector: 'cs-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {

  QUESTIONS: { question: string, answer: string }[] = [
    {
      question: "Can you describe your service in a few sentences?",
      answer: "Cultured Supply offers a product design and manufacturing service for creators to bring their product ideas to reality. This includes design services, material testing and sampling, rapid prototyping, and full commercial scale production direct from the factory."
    },
    {
      question: "Who is the service for?",
      answer: "Our services are designed for creators, entrepreneurs, and brands who want to develop and manufacture high-quality, unique products made from next-generation materials. We focus on working with brands that want ethical, transparent production to be the core of who they are and what they stand for."
    },
    {
      question: "What services do you offer?",
      answer: "We offer product design, sampling, and full commercial production capabilities. This includes: </br><ul><li>Technical design packages</li><li>CLO3D design service</li><li>Sampling</li><li>Full-scale production</li></ul>"
    },
    {
      question: "What products do you make?",
      answer: "We have the ability to design and produce products ranging from high-end luxury to more affordable goods. We offer both pre-set designs (white-label) as well as produce highly customised designs. Our current product capabilities include: apparel and accessories, automotive, bags, footwear, homegoods, hospitality, sporting goods, and more."
    },
    {
      question: "What materials do you use?",
      answer: "We work directly with more than 20 suppliers at the forefront of material innovation. Our team specializes in making products with recycled, biobased, and other next generation materials (think apple and cactus leather) wherever possible. This represents our deep commitment to making products that are nicer on the planet and better for human health, but we will never greenwash or sacrifice on performance."
    },
    {
      question: "How are you different from other services?",
      answer: "Three things to mention: </br><ul><li>We either own or work directly inside the factories.</li><li>We have integrated both product design and manufacturing capabilities in one place.</li><li>We specialize in using recycled, biobased, and other next generation materials at the forefront of performance and sustainability.</li></ul>"
    },
    {
      question: "Why should I work with Cultured Supply?",
      answer: "By working with us, the design and production of your products become part of your brand’s identity. You will be able to tell your customers: </br><ul><li>What’s inside the product?</li><li>How is the product made?</li><li>Where does the product come from?</li><li>Who’s involved with making it?</li></ul></br></br>You can now go directly from the factory to your audience. No more gatekeepers."
    },
    {
      question: "Who are the people behind the service?",
      answer: "It’s a partnership between <a href='manifesto' target='_blank'>Cultured Supply</a> and <a href='https://www.veshinfactory.com/' target='_blank'>Veshin Factory</a>. The partnership brings together Cultured Supply’s experience in procurement and supply chains with Veshin’s design and manufacturing capabilities."
    },
    {
      question: "What experience do you have with designing and manufacturing products?",
      answer: "Our team has 20+ years experience designing and manufacturing products for brands all over the world. We can provide specific case studies and additional information upon request."
    },
    {
      question: "How long does it take to manufacture a product?",
      answer: "Timelines vary depending on the level of customization and upfront design work needed. It takes about 2-3 months from the start of production to when it arrives at your doorstep."
    },
    {
      question: "How much does your service cost?",
      answer: "<ul><li><b>Design services: </b>quoted on a case-by-case basis. Technical packages start at $1,000.</li><li><b>Samples: </b>we charge 3x the factory cost for all samples. This helps to cover the upfront design work and cost to produce the sample.</li><li><b>Commercial production: </b>product cost is highly dependent on material choices, customization, and volumes. We typically start with the price point you want to sell the product for and work backwards from there to figure out the best combination of materials and customization.</li></ul>"
    },
    {
      question: "What are your minimum order quantities (MOQs)?",
      answer: "The MOQs for each factory and product category are slightly different. A good rule of thumb is 100 - 1,000 units.  100 units for larger items like backpacks or duffel bags and 1,000 units for smaller accessories like key chains."
    },
    {
      question: "How do you decide which factory to use for my product?",
      answer: "We only work with pre-vetted factories that we own or where our design teams work inside of. Each factory has their own speciality from a product and material standpoint. In order for us to choose the best factories and materials, we have to know more about the product you are looking to create and what price point you plan to sell it for. Ex:// one factory may specialize in lower volume, more custom luxury handbags vs another that is a better fit for higher volume, more general sporting goods they want to sell at a more reasonable price point."
    },
    {
      question: "Where are your factories located?",
      answer: "We currently have factories in Brazil, China, Colombia, and Italy."
    },
    {
      question: "Do you handle import/export services?",
      answer: "Our team handles the vast majority of logistics and prepares import/export paperwork on all orders. Some rules and regulations require the buyer to submit certain paperwork directly. When this is the case our team will walk you through the process."
    },
    {
      question: "How do I get started?",
      answer: "The first step is to fill out the <a href='https://share-eu1.hsforms.com/2JhNSVtEpTf-y1K3Ki-HZ0wf1g9n' target='_blank'>form</a> to tell us more about what you are looking to create. Once we receive this, we will reach out to set up a call to discuss further. Following the call, we will send out a preliminary quote for requested services."
    },
    {
      question: "Who can I contact to learn more before filling out the form?",
      answer: "You can contact Cultured Supply at <a href='mailto:info@culturedsupply.com'>info@culturedsupply.com</a> or call us at <a href='tel:+13132417544'>+1 (313) 241-7544</a>."
    },
  ];

  expandedIndex = 0;

  constructor() { }

  ngOnInit(): void {
  }

  parse(i: number): void {
    document.getElementById(('markdown_' + i).toString())!.innerHTML = marked.parse(this.QUESTIONS[i].answer);
  }
}
