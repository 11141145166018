import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ToastService} from "../../../shared/services/toast.service";

@Component({
  selector: 'cs-info-modal-window',
  templateUrl: './landing-info-modal-window.component.html',
  styleUrls: ['./landing-info-modal-window.component.scss']
})
export class LandingInfoModalWindowComponent implements OnInit {

  constructor(    @Inject(MAT_DIALOG_DATA) public data: {message: string, email: string},
                  private toastService: ToastService) { }

  ngOnInit(): void {
  }

  copyReferal(): void {
    const cb = navigator.clipboard;
    cb.writeText(this.data.email + ' has invited you to join a buyer pool at  Cultured Supply https://www.builder.algae.culturedsupply.com so you can procure biobased products for your supply chain. Welcome to the regenerative future!').then(() => this.toastService.success("Invite has been copied!"));
  }
}
